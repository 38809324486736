<template>
  <div>


    <div style="" class="background">

      <video-background
          :src="video"
          style="max-height: 100vh; height: 100vh; "
      >
      </video-background>

      <div class="div-koc-logo">
        <img :src="logo" class="img-koc-logo">
      </div>


      <div class="div-grid">

        <!--  -------------------- Links -------------------------------------      -->
        <!--        <div style="display: grid; align-content: space-between;">-->
        <div style="display: grid;">

          <!--  -------------------- Kategorie 1 Links -------------------------------------      -->
          <div v-for="(item, index) in pProductsTV4" v-if="index < 2">
            <div>
              <div class="div-category-text">
                {{ item.name }}


              </div>

              <div class="div-product-text" v-for="(it, i) in item.productList">

                <div>
                  {{ it.nr }}
                </div>

                <div>
                  <div style="display: flex;">
                    <div>
                      {{ it.product }}
                    </div>
                    <div style="display: flex">
                      <div class="div-allergien" v-for="(a,n) in it.allergien">
                        <label>{{ a.kurzel.toLowerCase()}}</label><label v-if="it.allergien.length-1>n ">,</label>
                      </div>
                      <div class="div-allergien" v-for="(a,n) in it.zusatzstoffe">
                        <label v-if="it.allergien.length>0 && n ===0 ">,</label><label>{{ a.kurzel.toLowerCase()}}</label><label v-if="it.zusatzstoffe.length-1>n ">,</label>
                      </div>
                    </div>
                  </div>
                  <div class="div-product-subtext">
                    {{ it.description }}
                  </div>
                </div>

                <div>
                  {{ it.price }}
                </div>

              </div>
            </div>
          </div>

        </div>


        <!--  -------------------- Rechts -------------------------------------      -->
        <!--        <div style="display: grid; align-content: space-between;">-->
        <div style="display: grid; ">

          <!--  -------------------- Kategorie 1 Rechts -------------------------------------      -->
          <div v-for="(item, index) in pProductsTV4" v-if="index > 1">
            <div>
              <div class="div-category-text">
                {{ item.name }}


              </div>

              <div class="div-product-text" v-for="(it, i) in item.productList">

                <div>
                  {{ it.nr }}
                </div>

                <div style="margin-left: 15px">
                  <div style="display: flex;">
                    <div>
                      {{ it.product }}
                    </div>
                    <div style="display: flex">
                      <div class="div-allergien" v-for="(a,n) in it.allergien">
                        <label>{{ a.kurzel.toLowerCase()}}</label><label v-if="it.allergien.length-1>n ">,</label>
                      </div>
                      <div class="div-allergien" v-for="(a,n) in it.zusatzstoffe">
                        <label v-if="it.allergien.length>0 && n ===0 ">,</label><label>{{ a.kurzel.toLowerCase()}}</label><label v-if="it.zusatzstoffe.length-1>n ">,</label>
                      </div>
                    </div>
                  </div>
                  <div class="div-product-subtext">
                    {{ it.description }}
                  </div>
                </div>

                <div>
                  {{ it.price }}
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player';
// import Video from "../assets/production ID_4042728.mp4";
import Flamme1 from "../assets/Flamme3.mp4";
import logo from "../assets/koc_logo.png";

export default {
  name: "CompTV4",

  props: [
    'pProductsTV1',
    'pProductsTV2',
    'pProductsTV3',
    'pProductsTV4',
  ],

  components: {
    VideoBackground
  },
  data() {
    return {
      video: Flamme1,
      logo:logo
    }
  }
}
</script>

<style scoped>
@import "../css/TV1.css";

</style>

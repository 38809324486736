import Vue from 'vue'
import vuetify from '@/plugins/vuetify'; // path to vuetify export
import App from './App.vue'
import router from './router'
import store from './store'


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
import TV1 from "@/views/TV1";
import TV2 from "@/views/TV2";
import TV3 from "@/views/TV3";
import TV4 from "@/views/TV4";
import TV5 from "@/views/TV5";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'TV1',
    component: TV1
  },
  {
    path: '/tv1',
    name: 'TV1',
    component: TV1
  },
  {
    path: '/tv2',
    name: 'TV2',
    component: TV2
  },
  {
    path: '/tv3',
    name: 'TV3',
    component: TV3
  },
  {
    path: '/tv4',
    name: 'TV4',
    component: TV4
  },
  {
    path: '/tv5',
    name: 'TV5',
    component: TV5
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
<div>
  <CompTV4
      :pProductsTV4="pProductsTV4"
  />
</div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player';
import Flamme1 from "../assets/Flamme3.mp4";
import logo from "../assets/koc_logo.png";
import CompTV2 from "@/components/CompTV2";
import CompTV3 from "@/components/CompTV3";
import CompTV4 from "@/components/CompTV4";

export default {
  name: "TV4",

  props: [
    'pProductsTV1',
    'pProductsTV2',
    'pProductsTV3',
    'pProductsTV4',
    'pChangeTVTime'
  ],

  components: {
    CompTV2,
    CompTV3,
    CompTV4,
    VideoBackground
  },
  data() {
    return {
      video: Flamme1,
      logo:logo
    }
  },
  async created() {
    if (process.env.VUE_APP_FIRMA_ID === "1"){
      setTimeout(()=>{window.location.href = 'https://www.koc-doner-welt.de/tv1'}, this.pChangeTVTime);
    }


    if (process.env.VUE_APP_FIRMA_ID === "2"){
      setTimeout(()=>{window.location.href = 'https://www.koc2.koc-doner-welt.de/tv2'}, 70000);
    }
  }
}
</script>

<style scoped>
@import "../css/TV1.css";

</style>

<template>
<div>
  <CompTV5
      :pProductsTV4="pProductsTV4"
      :pSound1="sound1"
  />


</div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player';
import Flamme1 from "../assets/Flamme3.mp4";
import logo from "../assets/koc_logo.png";
import CompTV2 from "@/components/CompTV2";
import CompTV3 from "@/components/CompTV3";
import CompTV4 from "@/components/CompTV4";
import CompTV5 from "@/components/CompTV5";

// import sound1 from "../assets/Istanbul Dreams - Instrumental Turkish Lounge Music.mp3";
import sound1 from "../assets/0Selam.mp3";

export default {
  name: "TV5",

  props: [
    'pProductsTV1',
    'pProductsTV2',
    'pProductsTV3',
    'pProductsTV4',
    'pProductsTV5',
    'pChangeTVTime'
  ],

  components: {
    CompTV2,
    CompTV3,
    CompTV4,
    CompTV5,
    VideoBackground
  },
  data() {
    return {
      video: Flamme1,
      logo:logo,
      sound1:sound1,
    }
  },
  async created() {



    // setTimeout(()=>{this.$router.push("/tv3")}, this.pChangeTVTime);
    // setTimeout(()=>{window.location.href = 'https://www.koc-doner-welt.de/tv1'}, this.pChangeTVTime);
    // setTimeout(this.changeTV1, this.pChangeTVTime);
  }
}
</script>

<style scoped>
@import "../css/TV1.css";

</style>

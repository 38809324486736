<template>
  <div>


    <div style="" class="background">

      <video-background
          :src="video"
          style="max-height: 100vh; height: 100vh; "
      >
      </video-background>

      <div class="div-koc-logo">
        <img :src="logo" class="img-koc-logo">
      </div>


      <div class="div-grid" >

          <audio controls autoplay loop>
            <!--    <source :src="sound1" type="audio/ogg">-->
            <source :src="pSound1" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
      </div>


    </div>

  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player';
// import Video from "../assets/production ID_4042728.mp4";
import Flamme1 from "../assets/Flamme3.mp4";
import logo from "../assets/koc_logo.png";

export default {
  name: "CompTV5",

  props: [
    'pProductsTV1',
    'pProductsTV2',
    'pProductsTV3',
    'pProductsTV4',
    'pProductsTV5',
    'pSound1',
  ],

  components: {
    VideoBackground
  },
  data() {
    return {
      video: Flamme1,
      logo:logo,
    }
  }
}
</script>

<style scoped>
@import "../css/TV1.css";

</style>

<template>
  <div>
    <CompTV1
        :pProductsTV1="pProductsTV1"
    />
  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player';
// import Video from "../assets/production ID_4042728.mp4";
import Flamme1 from "../assets/Flamme3.mp4";
import logo from "../assets/koc_logo.png";
import CompTV1 from "@/components/CompTV1";

export default {
  name: "TV1",

  props: [
    'pProductsTV1',
    'pProductsTV2',
    'pProductsTV3',
    'pChangeTVTime'
  ],

  components: {
    CompTV1,
    VideoBackground
  },
  data() {
    return {
      video: Flamme1,
      logo:logo
    }
  },

  async created() {
    if (process.env.VUE_APP_FIRMA_ID === "1"){
    setTimeout(()=>{window.location.href = 'https://www.koc-doner-welt.de/tv4'}, this.pChangeTVTime);
    }


    if (process.env.VUE_APP_FIRMA_ID === "2"){
    //setTimeout(()=>{window.location.href = 'https://www.koc2.koc-doner-welt.de/tv4'}, this.pChangeTVTime);
    //   setTimeout(()=>{window.location.href = 'https://www.koc2.koc-doner-welt.de/tv1'}, 150000);
    }

  }
}
</script>

<style scoped>
@import "../css/TV1.css";

</style>
